import React, {Component} from 'react';
import {Button, Container, Jumbotron, ListGroup} from "react-bootstrap";

class Email extends Component {
    render() {
        return (
            <>
                <Container>
                    <Jumbotron rounded className="mt-3 bg-light">
                        <Container>
                            <h1>Email</h1>
                            <p>
                                Connect with your clients and increase customer retention with Email Marketing.
                            </p>
                        </Container>
                    </Jumbotron>
                    <ListGroup>
                        <ListGroup.Item>

                            <Button href="https://www.itdomains.net/products/email-marketing" title="Email Marketing" variant="primary" size="lg" block>
                                Email Marketing
                            </Button>
                        </ListGroup.Item>
                    </ListGroup>
                    <ListGroup className="mt-3">
                        <ListGroup.Item>

                            <Button href="https://www.itdomains.net/products/email" title="Email" variant="primary" size="lg" block>
                                Email
                            </Button>
                        </ListGroup.Item>
                    </ListGroup>
                    <ListGroup className="mt-3">
                        <ListGroup.Item>

                            <Button href="https://www.itdomains.net/products/email-marketing" title="Workspace Online Storage" variant="primary" size="lg" block>
                                Workspace Online Storage
                            </Button>
                        </ListGroup.Item>
                    </ListGroup>
                    <ListGroup className="mt-3 mb-3">
                        <ListGroup.Item>

                            <Button href="https://www.itdomains.net/products/calendar" title="Workspace Calendar" variant="primary" size="lg" block>
                                Workspace Calendar
                            </Button>
                        </ListGroup.Item>
                    </ListGroup>
                </Container>
            </>
        );
    }
}

export default Email;