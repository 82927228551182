import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import Main from "./Component/Main"

function App() {
  return (
    <div>
      <Main />
    </div>
  )
}

export default App
