import React, {Component} from 'react'
import CarouselBox from "../Component/CarouselBox"
import {Card, Container, CardDeck, Button} from "react-bootstrap"
import imaDomain from '../assets/img/domain.jpg'
import imaHost from '../assets/img/hosting.jpg'
import imaSecurity from '../assets/img/security.jpg'
import imaEmail from '../assets/img/email.jpg'

class Home extends Component {
    render() {
        return (
            <>

                    <CarouselBox />

                <Container>
                    <CardDeck className="m-4">
                        <Card>
                            <Card.Img
                                variant="top"
                                src={imaDomain}
                            />
                            <Card.Body>
                                <Card.Title>DOMAINS</Card.Title>
                                <Card.Text>Each and every domain name comes with all you need to get online.</Card.Text>
                                <Button href={'/domains'}>More</Button>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Img
                                variant="top"
                                src={imaHost}
                            />
                            <Card.Body>
                                <Card.Title>HOSTING</Card.Title>
                                <Card.Text>Get total control with SSD-powered VPS Hosting that gives you full root access and optional control panels, backups with performance monitoring, unlimited traffic and a 99.9% uptime guarantee.</Card.Text>
                                <Button href={'/hosting'}>More</Button>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                    <CardDeck className="m-4">
                        <Card>
                            <Card.Img
                                variant="top"
                                src={imaEmail}
                            />
                            <Card.Body>
                                <Card.Title>EMAIL</Card.Title>
                                <Card.Text>Professional Email with the features you need.</Card.Text>
                                <Button href={'/email'}>More</Button>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Img
                                variant="top"
                                src={imaSecurity}
                            />
                            <Card.Body>
                                <Card.Title>SECURITY</Card.Title>
                                <Card.Text>Get peace of mind with daily malware scans and guaranteed malware removal should any problems be detected.</Card.Text>
                                <Button href={'/security'}>More</Button>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </Container>
            </>
        );
    }
}

export default Home;