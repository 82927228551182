import React, {Component} from 'react'
import {Container, Navbar, Nav} from "react-bootstrap"
import logo from '../assets/img/logo-192.png'
import {BrowserRouter as Router, Route, Switch, Link} from "react-router-dom"
import Home from "../pages/Home"
import Domains from "../pages/Domains"
import Email from "../pages/Email"
import Hosting from "../pages/Hosting"
import Security from "../pages/Security"

class Main extends Component {
    render() {

        return (

            <div>
                <>
                    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
                        <Container>
                            <Navbar.Brand>
                                <img src={logo}
                                     height="30"
                                     width="30"
                                     className="d-inline-block align-top"
                                     alt="Server Global"
                                /> Server Global
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="mr-auto">
                                    <Nav.Link href="/"> Home </Nav.Link>
                                    <Nav.Link href="/domains"> Domains </Nav.Link>
                                    <Nav.Link href="/hosting"> Hosting </Nav.Link>
                                    <Nav.Link href="/email"> Email </Nav.Link>
                                    <Nav.Link href="/security"> Security </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    
                    <Router>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path={'/domains'} component={Domains} />
                            <Route exact path={'/hosting'} component={Hosting} />
                            <Route exact path={'/email'} component={Email} />
                            <Route exact path={'/security'} component={Security} />
                        </Switch>
                    </Router>
                    <div className="h-100 bg-dark text-center pt-2 pb-2"  style={{color: '#FFF'}}><sup>&copy;</sup>Copyright {new Date().getFullYear()} <a href="mailto:info@serverglobal.net">info@serverglobal.net</a></div>
                </>
            </div>
        )
    }
}
export default Main