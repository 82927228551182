import React, {Component} from 'react';
import {Button, Card, Container, Jumbotron, ListGroup} from "react-bootstrap";

class Hosting extends Component {
    render() {
        return (
            <>
                <Container>
                    <Jumbotron rounded className="mt-3 bg-light">
                        <Container>
                            <h1>Hosting</h1>
                            <p></p>
                        </Container>
                    </Jumbotron>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>
                                Hosting
                            </Card.Title>
                            <Card.Text>
                                <ListGroup>
                                    <ListGroup.Item>
                                        <h3>VPS Hosting</h3> Get total control with SSD-powered VPS Hosting that gives you full root access and optional control panels, backups with performance monitoring, unlimited traffic and a 99.9% uptime guarantee.
                                        <Button href="https://www.itdomains.net/products/vps" title="VPS Hosting" variant="primary" size="lg" block>
                                            VPS Hosting
                                        </Button>
                                    </ListGroup.Item>
                                </ListGroup>
                                <ListGroup className="mt-3">
                                    <ListGroup.Item variant="secondary">
                                        <h3>Dedicated Server</h3> Get the power and flexibility you need with Dedicated Server hosting. Choose from three levels of control — Self Managed, Managed or Fully Managed.
                                        <Button href="https://www.itdomains.net/products/dedicated-server" title="Dedicated Server" variant="primary" size="lg" block>
                                            Dedicated Server
                                        </Button>
                                    </ListGroup.Item>
                                </ListGroup>
                                <ListGroup className="mt-3">
                                    <ListGroup.Item>
                                        <h3>cPanel</h3> Get your website online with us. All plans include one-click install, 99.9% uptime, 24/7 security monitoring and an easy-to-use control panel.
                                        <Button href="https://www.itdomains.net/products/cpanel" title="cPanel" variant="primary" size="lg" block>
                                            cPanel
                                        </Button>
                                    </ListGroup.Item>
                                </ListGroup>
                                <ListGroup className="mt-3">
                                    <ListGroup.Item variant="secondary">
                                        <h3>Plesk</h3> Windows web hosting is perfect for those who use ASP.NET, ASP or SQL Server. Enjoy one-click install, industry-leading load times, an easy-to-use interface and more.
                                        <Button href="https://www.itdomains.net/products/plesk" title="Plesk" variant="primary" size="lg" block>
                                            Plesk
                                        </Button>
                                    </ListGroup.Item>
                                </ListGroup>
                                <ListGroup className="mt-3">
                                    <ListGroup.Item>
                                        <h3>WordPress</h3> Our WordPress Hosting provides automatic setup, backups and software updates paired with 24/7, award-winning support. Get started in just a few clicks.
                                        <Button href="https://www.itdomains.net/products/wordpress" title="WordPress" variant="primary" size="lg" block>
                                            WordPress
                                        </Button>
                                    </ListGroup.Item>
                                </ListGroup>
                                <ListGroup className="mt-3">
                                    <ListGroup.Item variant="secondary">
                                        <h3>Business</h3> Business Hosting gives you the ultra-fast speed of a Virtual Private Server with a super simple control panel — a perfect fit for those who don’t have tech skills but still need powerful hosting.
                                        <Button href="https://www.itdomains.net/products/business" title="Business hosting" variant="primary" size="lg" block>
                                            Business
                                        </Button>
                                    </ListGroup.Item>
                                </ListGroup>

                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>

            </>
        );
    }
}

export default Hosting;