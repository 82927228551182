import React, {Component} from 'react';
import {Carousel} from "react-bootstrap";
import slideImg1 from '../assets/img/slider/slider-1.jpg'
import slideImg2 from '../assets/img/slider/slider-2.jpg'
import slideImg3 from '../assets/img/slider/slider-3.jpg'
import slideImg4 from '../assets/img/slider/slider-4.jpg'

class CarouselBox extends Component {
    render() {
        return (
            <div>
                <Carousel >
                    <Carousel.Item>
                        <img
                            src={slideImg1}
                            className="d-block w-100"
                            alt="Get your .COM"

                        />
                        <Carousel.Caption>
                            <h2>Get your .COM</h2>
                            <p>FIND A DOMAIN THAT FITS</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            src={slideImg2}
                            className="d-block w-100"
                            alt="IT Security"
                        />
                        <Carousel.Caption>
                            <h2>IT Security</h2>
                            <p>A vulnerability is a weakness in design, implementation, operation, or internal control.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            src={slideImg3}
                            className="d-block w-100"
                            alt=""
                        />
                        <Carousel.Caption>
                            <h2 style={{color: '#000'}}>CREATIVE SOLUTIONS</h2>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            src={slideImg4}
                            className="d-block w-100"
                            alt="Virtual Servers – Performance Up, costs Down"
                        />
                        <Carousel.Caption>
                            <h2>Virtual Servers – Performance Up, costs Down</h2>
                            <p>Flexible, lightning-fast, best-performing, zero setup fees, no hidden costs</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

export default CarouselBox;