import React, {Component} from 'react';
import {Container, Jumbotron, Accordion, Card, Button} from "react-bootstrap";

class Security extends Component {
    render() {
        return (
            <>
                <Container>
                    <Jumbotron rounded className="mt-3 bg-light">
                        <Container>
                            <h1>Security</h1>
                            <p></p>
                        </Container>
                    </Jumbotron>
                    <Accordion defaultActiveKey="0" className="mb-3">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Website Security
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    Get peace of mind with daily malware scans and guaranteed malware removal should any problems be detected.
                                    <a title={'Website Security'} href={'https://www.itdomains.net/products/website-security'}> More</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    SSL
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    Speak the language of security with an SSL certificate. The little green lock lets visitors know that you’ll keep their data safe.
                                    <a title={'SSL'} href={'https://www.itdomains.net/products/ssl'}> More</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                    Managed SSL Service
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    We handle the install and maintenance of your SSL - saving you time and energy.
                                     <a title={'Managed SSL Service'} href={'https://www.itdomains.net/products/ssl-managed'}> More</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                    Website Backup
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    Keep your data safe while you grow your business online. Automatic backups and one-click restore mean you’re ready for anything that comes your way.
                                    <a title={'Website Backup'} href={'https://www.itdomains.net/products/website-backup'}> More</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                    Code Signing Certificate
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    As an independent developer or programmer, your code is your reputation. If your release is hacked, infected, or altered in any way, customers won’t trust you enough to give you a second chance. Protect yourself, your customers and your good name with the rock-solid security of a code or driver signing certificate.
                                    <a title={'Code Signing Certificate'} href={'https://www.itdomains.net/products/code-signing-certificate'}> More</a>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Container>
            </>
        );
    }
}

export default Security;