import React, {Component} from 'react'
import {Container, Jumbotron, Card, Button} from "react-bootstrap"

class Domains extends Component {
    render() {
        return (
            <>
                <Container>
                    {/*<Image src={imgDom} fluid />*/}
                </Container>
                <Container>
                    <Jumbotron rounded className="mt-3 bg-light">
                        <Container>
                            <h1>Domain Registration</h1>
                            <p>
                                Each and every domain name comes with all you need to get online.
                            </p>
                        </Container>
                    </Jumbotron>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>
                                Domain Registration
                            </Card.Title>
                            <Card.Text>

                                <ul>
                                    <li><b>Domain Forwarding and Masking:</b> Direct any domain name you own to your website. Anyone who types that domain name into their browser is taken directly to your website.</li>
                                    <li><b>Domain Locking:</b> Domain locking prevents accidental or intentional transfers of domain ownership and stops anyone from redirecting your nameservers.</li>
                                    <li><b>Total DNS Control:</b> Manage your domain nameserver (DNS) records and set your email, FTP, sub-domains and website location all from one control panel.</li>
                                    <li><b>Change of Registration:</b> Assign your domain name to someone else or change the contacts for your domain online anytime.</li>
                                    <li><b>Status Alerts:</b> Monitor the status of your domain and get instant alerts if there’s been a change.</li>
                                    <li><b>Auto Renew Protection:</b>: No need to watch expiration dates to make sure you renew on time! Auto renew keeps your domains, hosting, website builders, and other products in your name and under your control.</li>
                                </ul>
                                <div>
                                    <Button href="https://www.itdomains.net/products/domain-registration" variant="primary" size="lg" block>
                                        Register Domain
                                    </Button>
                                    <Button href="https://www.secureserver.net/domains/bulk-domain-search.aspx?plid=567570" variant="primary" size="lg" block>
                                        Bulk Registration
                                    </Button>
                                    <Button href="https://www.itdomains.net/products/domain-transfer" variant="primary" size="lg" block>
                                        Domain Transfer
                                    </Button>
                                    <Button href="https://www.secureserver.net/domains/bulk-domain-transfer.aspx?plid=567570" variant="primary" size="lg" block>
                                        Bulk Transfer
                                    </Button>
                                    <Button href="https://www.itdomains.net/products/domain-backorder" variant="primary" size="lg" block>
                                        Domain Backorder
                                    </Button>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </>
        );
    }
}

export default Domains;